import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {
    simOnlyOldDataAllowance,
    simOnlyOldMonthlyCost,
    SimOnlyPlan,
    simOnlyPlanDataLimit, simOnlyPlanMonthlyCost
} from "../../Model/Sales/SimOnlyPlan";
import {ApplicationContext} from "../../ApplicationContext";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {addPromoQueryParam, getPromoQueryParam, SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import SuperdrugSelectedPlanDetailsCard from "../../Components/Superdrug/Card/SuperdrugSelectedPlanDetailsCard";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {isRequestError} from "../../Model/RequestError";
import {KeyValue} from "../../Types/KeyValue";
import CmsStaticHtmlContent from "../../Components/CmsStaticHtmlContent/CmsStaticHtmlContent";
import {UserDetails} from "../../Model/User/UserDetails";
import {fetchHybrisUser} from "../../Api/Hybris/UserManagementApi";
import {fetchPriceForPlanWithPromo} from "../../Api/Sales/PricesApi";

interface SelectableOtherPlansProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * List of SIM-only plans that could be selected by the customer.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

    /**
     * Information on the customers selected plan.
     */
    readonly selectedPlan: SimOnlyPlan

}

/**
 * Function will render the other plans pane and will let the customer select a different plan if they want to.
 */
const SelectableOtherPlans = (props: SelectableOtherPlansProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()

    const setupComponent = async () => {

    }

    React.useEffect(() => {
        setupComponent()
    }, [])

    const optOtherPlansPane = optHtmlContent(props.cmsContent, "sp-pane-sim-only-plans-other-horizontal")
    const optOtherPlanCard = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-other")
    const optSelectedOtherPlanCard = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-other-selected")
    const optPlanColourMap = props.cmsContent.find((content) => content.reference === "plan-colour-map")

    if (props.simOnlyPlans && optOtherPlansPane && optOtherPlanCard && optSelectedOtherPlanCard && optPlanColourMap) {
        const simOnlyPlanCards = props.simOnlyPlans.map((plan) => {
            const planColour = Object.hasOwn(optPlanColourMap.jsonContent, plan.packageCode) ?
                optPlanColourMap.jsonContent[plan.packageCode] : undefined

            let planPrice
            if (plan.promoDiscount) {
                planPrice = `£${(plan.monthlyPrice - plan.promoDiscount.value).toFixed(2)}`
            } else {
                planPrice = `£${plan.monthlyPrice.toFixed(2)}`
            }

            const dataAllowanceElement = plan.promoAddonLimit !=null ? (
                <div>
                    <s>{simOnlyOldDataAllowance(plan)}</s> <span>{simOnlyPlanDataLimit(plan)}</span>
                </div>
            ) : (
                <span>{simOnlyPlanDataLimit(plan)}</span>
            );

            const planPriceElement = plan.promoDiscount ? (
                <div>
                    <s>£{simOnlyOldMonthlyCost(plan)}</s> <span>£{simOnlyPlanMonthlyCost(plan)}</span>
                </div>
            ) : (
                <span>£{simOnlyPlanMonthlyCost(plan)}</span>
            );

            const templateTags: KeyValue<string | React.ReactNode | React.ReactNode[]>[] = [
                {key: "DATA_ALLOWANCE", value: dataAllowanceElement},
                {key: "PLAN_DESCRIPTION", value: plan.description},
                {key: "PLAN_PRICE", value: planPriceElement},
                {key: "PLAN_COLOUR", value: planColour}
            ]

            let cardContent
            if (plan.packageCode === props.selectedPlan.packageCode) {
                if (plan.promoCode === props.selectedPlan.promoCode) {
                    cardContent = renderDynamicContent(optSelectedOtherPlanCard, templateTags)
                } else {
                    cardContent = renderDynamicContent(optOtherPlanCard, templateTags)
                }
            } else {
                cardContent = renderDynamicContent(optOtherPlanCard, templateTags)
            }

            return (
                <div
                    onClick={() => navigate(addPromoQueryParam(SALES_PORTAL_ROUTES.SimOnly.Alternative(applicationContext.urlContext, plan.packageCode), plan.promoCode))}
                    style={{cursor: "pointer"}}
                >
                    {cardContent}
                </div>
            )
        })

        return renderDynamicContent(optOtherPlansPane, [
            {key: "OTHER_PLANS", value: simOnlyPlanCards},
            {
                key: "SIGNUP_LINK",
                value: addPromoQueryParam(SALES_PORTAL_ROUTES.SimOnly.Purchase(applicationContext.urlContext, props.selectedPlan.packageCode), props.selectedPlan.promoCode)
            }
        ])
    } else {
        return null
    }
}

interface SuperdrugSimOnlyOtherPlansSelectionPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * List of SIM-only plans that could be selected by the customer.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the Superdrug-specific other plan selection page. This page will allow a customer to select a
 * different plan to the one that has already been selected before proceeding to the signup page.
 */
const SuperdrugSimOnlyOtherPlansSelectionPage = (
    props: SuperdrugSimOnlyOtherPlansSelectionPageProps
): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()
    const location = useLocation()
    const currentUrl = location.pathname + location.search
    const {planCode} = useParams<keyof { readonly planCode: string }>()
    const promoCode = getPromoQueryParam(useSearchParams())

    const [componentData, setComponentData] = React.useState<{
        readonly selectedPlan: SimOnlyPlan
        readonly userDetails: UserDetails
        readonly simOnlyPlans: SimOnlyPlan[]
    }>()

    const [simOnlyPlans, setSimOnlyPlans] = React.useState<SimOnlyPlan[]>(props.simOnlyPlans)

    /**
     * Function will fetch all the information needed for this component before rendering it.
     */
    const setupComponent = async () => {
        const accessToken = await applicationContext.accessToken()
        if (!accessToken) {
            navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
            return
        }

        const maybeUserDetails = await fetchHybrisUser(applicationContext.salesPortalApiDomain, accessToken)
        if (isRequestError(maybeUserDetails)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        const optStaffPromoCode = maybeUserDetails.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null

        if (optStaffPromoCode) { // Customer hasn't selected a plan yet for SIH journey.
            const uniquePlanPackages = Array.from(new Set(props.simOnlyPlans.map(plan => plan.packageCode)))
            const updatedPlans = await Promise.all(
                uniquePlanPackages.map(async (planPackage) => {
                    const maybePlanPrice = await fetchPriceForPlanWithPromo(
                        applicationContext.reseller, applicationContext.salesPortalApiDomain, planPackage!!, optStaffPromoCode
                    )
                    const plan = props.simOnlyPlans.find(plan => plan.packageCode === planPackage)
                    if (plan && !isRequestError(maybePlanPrice)) {
                        return {
                            ...plan,
                            promoCode: optStaffPromoCode,
                            promoDiscount: {value: maybePlanPrice.promoDiscount!!, currency: "GBP"},
                            promoPercentageDiscount: maybePlanPrice.promoPercentageDiscount
                        }
                    }
                    return null
                })
            )
            const validPlans = updatedPlans.filter((plan): plan is NonNullable<typeof plan> => plan !== null)
            const maybeSelectedPlan = validPlans
                ?.find((plan) => {
                    return plan.packageCode === planCode && plan.promoCode === optStaffPromoCode
                }) ?? null;

            if (!maybeSelectedPlan) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext));
                return;
            }

            setComponentData({
                selectedPlan: maybeSelectedPlan,
                userDetails: maybeUserDetails,
                simOnlyPlans: validPlans
            });
        } else {
            const maybePlan = simOnlyPlans
                ?.find((plan) => {
                    return plan.packageCode === planCode && plan.promoCode === promoCode
                }) ?? null;

            if (!maybePlan) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext));
                return;
            }

            setComponentData({
                selectedPlan: maybePlan,
                userDetails: maybeUserDetails,
                simOnlyPlans: simOnlyPlans
            });
        }
    }

    React.useEffect(() => {
        setupComponent()
    }, [currentUrl])

    const optOtherSimOnlyPlansPage = optHtmlContent(props.cmsContent, "sp-page-other-sim-only-plans")

    if (!optOtherSimOnlyPlansPage || !componentData) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else {
        const optPromoCode = componentData.userDetails?.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null

        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="secondary">
                {renderDynamicContent(optOtherSimOnlyPlansPage, [
                    {
                        key: "SELECTED_PLAN_INFO_CARD",
                        value: <SuperdrugSelectedPlanDetailsCard
                            cmsContent={props.cmsContent}
                            plan={componentData.selectedPlan}
                        />
                    },
                    {
                        key: "HAVE_YOU_SEEN_OUR_PLANS_CARD",
                        value: <CmsStaticHtmlContent
                            cmsContent={props.cmsContent}
                            reference="sp-card-other-sim-only-plans"
                        />
                    },
                    {
                        key: "OTHER_PLANS",
                        value: <SelectableOtherPlans
                            cmsContent={props.cmsContent}
                            selectedPlan={componentData.selectedPlan}
                            simOnlyPlans={componentData.simOnlyPlans}
                        />
                    }
                ])}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugSimOnlyOtherPlansSelectionPage