import * as React from "react"
import {SimOnlyPlan, simOnlyPlanDataLimit} from "../../../Model/Sales/SimOnlyPlan";
import {optHtmlContent, renderDynamicContent} from "../../../Utilities/CmsUtilities";
import {currencyPrettyPrint} from "../../../Model/Configuration/CurrencyConfiguration";
import {CmsContent} from "../../../Model/CmsContent/CmsContent";
import {ApplicationContext} from "../../../ApplicationContext";

interface SuperdrugSelectedPlanDetailsCardProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Plan information to be displayed in the card.
     */
    readonly plan: SimOnlyPlan

    /**
     * Information on how much the selected plan costs.
     */
    // readonly priceWithPromo: PlanPrice | null

}

/**
 * Function will render the selected plan details card that can be found on pages after the user has authenticated
 * and selected a plan.
 */
const SuperdrugSelectedPlanDetailsCard = (props: SuperdrugSelectedPlanDetailsCardProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)

    if (props.plan.promoCode) {
        const optSelectedPlanDetailsCard = optHtmlContent(props.cmsContent, "sp-card-selected-plan-details-promo")

        if (optSelectedPlanDetailsCard) {
            let discountString
            if (props.plan.promoPercentageDiscount) {
                discountString = `-${props.plan.promoPercentageDiscount}%`
            } else if (props.plan.promoDiscount) {
                discountString = `-£${props.plan.promoDiscount.value.toFixed(2)}`
            // } else if (props.plan.promoPriceOverride) {
            //     discountString = `-£${(props.plan.monthlyPrice - props.priceWithPromo.promoPriceOverride).toFixed(2)}`
            } else {
                discountString = "" // Shouldn't be possible to get here
            }

            const discountedPrice = props.plan.monthlyPrice - (props.plan.promoDiscount ? props.plan.promoDiscount.value : 0)

            return renderDynamicContent(optSelectedPlanDetailsCard, [
                {   key: "PLAN_DESCRIPTION",
                    value: props.plan.promoAddonLimit ? `<s style="opacity: 0.5">${props.plan.description}</s> <span>${simOnlyPlanDataLimit(props.plan)}</span>` : props.plan.description
                },
                {key: "DISCOUNTED_PRICE", value: `£${discountedPrice.toFixed(2)}`},
                {
                    key: "PLAN_PRICE",
                    value: props.plan.promoDiscount ? currencyPrettyPrint(props.plan.monthlyPrice, applicationContext.appConfig.currencyConfiguration) : null
                },
                {key: "PROMO_DESCRIPTION", value: discountString},
                {key: "PROMO_DISCOUNT", value: discountString}
            ])
        } else {
            return null
        }
    } else {
        const optSelectedPlanDetailsCard = optHtmlContent(props.cmsContent, "sp-card-selected-plan-details")

        if (optSelectedPlanDetailsCard) {
            return renderDynamicContent(optSelectedPlanDetailsCard, [
                {key: "PLAN_DESCRIPTION", value: props.plan.description},
                {
                    key: "PLAN_PRICE",
                    value: currencyPrettyPrint(props.plan.monthlyPrice, applicationContext.appConfig.currencyConfiguration)
                }
            ])
        } else {
            return null
        }
    }
}

export default SuperdrugSelectedPlanDetailsCard