import * as React from "react"
import {Route, Routes, useSearchParams} from "react-router-dom";
import {ApplicationContext} from "../ApplicationContext";
import SplashPage from "../Pages/SplashPage/SplashPage";
import SimOnlyPlansPage from "../Pages/SimOnlyPlansPage/SimOnlyPlansPage";
import SimOnlyPlanDetailsPage from "../Pages/SimOnlyPlanDetailsPage/SimOnlyPlanDetailsPage";
import {SimOnlyPlan} from "../Model/Sales/SimOnlyPlan";
import SimOnlyPlanPurchasePage from "../Pages/SimOnlyPlanPurchasePage/SimOnlyPlanPurchasePage";
import SimInHandCapturePage from "../Pages/SimInHandCapturePage/SimInHandCapturePage";
import {CmsContent} from "../Model/CmsContent/CmsContent";
import SimInHandPlanSelectionPage from "../Pages/SimInHandPlanSelectionPage/SimInHandPlanSelectionPage";
import BlogListPage from "../Pages/BlogListPage/BlogListPage";
import BlogViewPage from "../Pages/BlogViewPage/BlogViewPage";
import LoginPage from "../Pages/LoginPage/LoginPage";
import {HybrisCallbackPage} from "../Pages/LoginPage/SuperdrugLoginPage";
import UserDetailsUpdatePage from "../Pages/UserDetailsUpdatePage/UserDetailsUpdatePage";
import InternalErrorPage from "../Pages/InternalErrorPage/InternalErrorPage";
import SimOnlyOtherPlansSelectionPage from "../Pages/SimOnlyOtherPlansSelectionPage/SimOnlyOtherPlansSelectionPage";
import SimOnlyOrderConfirmationPage from "../Pages/SimOnlyOrderConfirmationPage/SimOnlyOrderConfirmationPage";
import NotFoundErrorPage from "../Pages/NotFoundErrorPage/NotFoundErrorPage";
import PortSelectionPage from "../Pages/PortSelectionPage/PortSelectionPage";
import PortCapturePage from "../Pages/PortCapturePage/PortCapturePage";
import SimInHandPlanPurchasePage from "../Pages/SimInHandPlanPurchasePage/SimInHandPlanPurchasePage";
import SimInHandOrderConfirmationPage from "../Pages/SimInHandOrderConfirmationPage/SimInHandOrderConfirmationPage";
import CoverageCheckerPage from "../Pages/CoverageCheckerPage/CoverageCheckerPage";
import LogoutPage from "../Pages/LogoutPage/LogoutPage";
import TermsAndConditionsPage from "../Pages/TermsAndConditions/TermsAndConditionsPage";
import AgreementPlanPreviewPage from "../Pages/AgreementPlanPreviewPage/AgreementPlanPreviewPage";
import {PromotionCardContent} from "../Model/Sales/PromotionCardContent";

/**
 * Constant defines all known routes within the Sales Portal application.
 */
export const SALES_PORTAL_ROUTES = {
    Home: (context: string): string =>
        `${context}/`,

    InternalError: (context: string): string =>
        `${context}/error`,
    NotFound: (context: string): string =>
        `${context}/not-found`,

    Login: (context: String): string =>
        `${context}/login`,

    Logout: (context: string): string =>
        `${context}/logout`,

    HybrisCallback: (context: string): string =>
        `${context}/hybris-callback`,

    BlogList: (context: string): string =>
        `${context}/blog`,
    BlogView: (context: string, reference: string): string =>
        `${context}/blog/${reference}`,

    Coverage: (context: string): string =>
        `${context}/coverage-checker`,

    Terms: (context: string): string =>
        `${context}/terms`,

    SimOnly: {
        UserUpdate: (context: string): string =>
            `${context}/sim-only/user/update`,
        Deals: (context: string): string =>
            `${context}/sim-only/deals`,
        Alternative: (context: string, planCode: string): string =>
            `${context}/sim-only/deals/${planCode}/alternative`,
        Details: (context: string, planCode: string): string =>
            `${context}/sim-only/deals/${planCode}/view`,
        Purchase: (context: string, planCode: string): string =>
            `${context}/sim-only/deals/${planCode}/purchase`,
        Confirmation: (context: string): string =>
            `${context}/sim-only/confirmation`,
        PlanAgreementPreview: (context: string, planCode: string): string =>
            `${context}/sim-only/deals/${planCode}/plan-agreement-preview`,
    },

    SimInHand: {
        activate: (context: string): string =>
            `${context}/activate`,
        UserUpdate: (context: string): string =>
            `${context}/sim-in-hand/user/update`,
        PlanSelection: (context: string, planCode: string) =>
            `${context}/sim-in-hand/${planCode}`,
        Purchase: (context: string, planCode: string): string =>
            `${context}/sim-in-hand/${planCode}/purchase`,
        Confirmation: (context: string): string =>
            `${context}/sim-in-hand/confirmation`,
        PlanAgreementPreview: (context: string, planCode: string): string =>
            `${context}/sim-in-hand/${planCode}/plan-agreement-preview`,
    },

    Port: {
        PortSelection: (context: string, planCode: string): string =>
            `${context}/keeping-your-number/${planCode}`,
        PortCapture: (context: string, planCode: string): string =>
            `${context}/switching-to-us/${planCode}`
    }
}

interface SalesPortalRoutesProps {

    /**
     * Array of all Sales Portal content currently stored in the CMS.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of all known SIM-only plans that could be selected by the customer.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

    /**
     * Array of all the best SIM-only plans. The criteria for "best" is reseller-dependent.
     */
    readonly bestSimOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will match the current browser path to an appropriate web page.
 */
const SalesPortalRoutes = (props: SalesPortalRoutesProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    return (
        <Routes>
            <Route path={SALES_PORTAL_ROUTES.Home(applicationContext.urlContext)} element={
                <SplashPage
                    cmsContent={props.cmsContent}
                    simOnlyPlans={props.simOnlyPlans}/>
            }/>

            <Route path={SALES_PORTAL_ROUTES.Login(applicationContext.urlContext)} element={
                <LoginPage cmsContent={props.cmsContent}/>
            }/>
            <Route path={SALES_PORTAL_ROUTES.Logout(applicationContext.urlContext)} element={
                <LogoutPage/>
            }/>

            <Route path={SALES_PORTAL_ROUTES.HybrisCallback(applicationContext.urlContext)} element={
                <HybrisCallbackPage/>
            }/>

            {applicationContext.appConfig.pagesConfiguration.blogPageEnabled && <Route
                path={SALES_PORTAL_ROUTES.BlogList(applicationContext.urlContext)}
                element={<BlogListPage cmsContent={props.cmsContent}/>}
            />}
            {applicationContext.appConfig.pagesConfiguration.blogPageEnabled && <Route
                path={SALES_PORTAL_ROUTES.BlogView(applicationContext.urlContext, ":reference")}
                element={<BlogViewPage cmsContent={props.cmsContent}/>}
            />}

            <Route
                path={SALES_PORTAL_ROUTES.Coverage(applicationContext.urlContext)}
                element={<CoverageCheckerPage cmsContent={props.cmsContent}/>}
            />

            <Route path={SALES_PORTAL_ROUTES.SimOnly.UserUpdate(applicationContext.urlContext)} element={
                <UserDetailsUpdatePage cmsContent={props.cmsContent} simInHand={false}/>
            }/>
            <Route path={SALES_PORTAL_ROUTES.SimOnly.Deals(applicationContext.urlContext)} element={
                <SimOnlyPlansPage cmsContent={props.cmsContent} simOnlyPlans={props.simOnlyPlans}/>
            }/>
            <Route path={SALES_PORTAL_ROUTES.SimOnly.Alternative(applicationContext.urlContext, ":planCode")}
                   element={
                       <SimOnlyOtherPlansSelectionPage cmsContent={props.cmsContent}
                                                       simOnlyPlans={props.simOnlyPlans}/>
                   }/>
            <Route
                path={SALES_PORTAL_ROUTES.SimOnly.Details(applicationContext.urlContext, ":planCode")}
                element={
                    <SimOnlyPlanDetailsPage
                        cmsContent={props.cmsContent}
                        simOnlyPlans={props.simOnlyPlans}
                    />
                }
            />
            <Route
                path={SALES_PORTAL_ROUTES.SimOnly.Purchase(applicationContext.urlContext, ":planCode")}
                element={<SimOnlyPlanPurchasePage cmsContent={props.cmsContent} simOnlyPlans={props.simOnlyPlans}/>}
            />
            <Route
              path={SALES_PORTAL_ROUTES.SimOnly.PlanAgreementPreview(applicationContext.urlContext, ":planCode")}
              element={<AgreementPlanPreviewPage contractType="newOrder" />}
            />
            <Route
                path={SALES_PORTAL_ROUTES.SimOnly.Confirmation(applicationContext.urlContext)}
                element={<SimOnlyOrderConfirmationPage cmsContent={props.cmsContent}/>}
            />

            <Route path={SALES_PORTAL_ROUTES.SimInHand.UserUpdate(applicationContext.urlContext)} element={
                <UserDetailsUpdatePage cmsContent={props.cmsContent} simInHand={true}/>
            }/>
            <Route path={SALES_PORTAL_ROUTES.SimInHand.activate(applicationContext.urlContext)} element={
                <SimInHandCapturePage cmsContent={props.cmsContent}/>
            }/>
            <Route
                path={SALES_PORTAL_ROUTES.SimInHand.PlanSelection(applicationContext.urlContext, ":planCode")}
                element={
                    <SimInHandPlanSelectionPage
                        cmsContent={props.cmsContent}
                        simOnlyPlans={props.simOnlyPlans}
                    />
                }
            />
            <Route
                path={SALES_PORTAL_ROUTES.SimInHand.Purchase(applicationContext.urlContext, ":planCode")}
                element={<SimInHandPlanPurchasePage cmsContent={props.cmsContent}/>}
            />
            <Route
              path={SALES_PORTAL_ROUTES.SimInHand.PlanAgreementPreview(applicationContext.urlContext, ":planCode")}
              element={<AgreementPlanPreviewPage contractType="newOrder" />}
            />
            <Route
                path={SALES_PORTAL_ROUTES.SimInHand.Confirmation(applicationContext.urlContext)}
                element={<SimInHandOrderConfirmationPage cmsContent={props.cmsContent}/>}
            />

            <Route path={SALES_PORTAL_ROUTES.Port.PortSelection(applicationContext.urlContext, ":planCode")}
                   element={
                       <PortSelectionPage cmsContent={props.cmsContent}/>
                   }/>
            <Route path={SALES_PORTAL_ROUTES.Port.PortCapture(applicationContext.urlContext, ":planCode")} element={
                <PortCapturePage cmsContent={props.cmsContent}/>
            }/>

            <Route path={SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext)} element={
                <InternalErrorPage cmsContent={props.cmsContent}/>
            }/>
            <Route path={SALES_PORTAL_ROUTES.Terms(applicationContext.urlContext)} element={
                <TermsAndConditionsPage cmsContent={props.cmsContent}/>
            }/>
            <Route path={SALES_PORTAL_ROUTES.NotFound(applicationContext.urlContext)} element={
                <NotFoundErrorPage cmsContent={props.cmsContent}/>
            }/>
            <Route path="*" element={
                <NotFoundErrorPage cmsContent={props.cmsContent}/>
            }/>
        </Routes>
    )
}

export const addPromoQueryParam = (url: string, promoCode: string | null): string => {
    return url + (promoCode ? (url.includes("?") ? "&" : "?") + "promo=" + encodeURIComponent(promoCode) : "")
}

export const addPlanCodeQueryParam = (url: string, planCode: string): string => {
    return url + (planCode ? (url.includes("?") ? "&" : "?") + "plan=" + encodeURIComponent(planCode) : "")
}

export const getPromoQueryParam = ([params, _]: [URLSearchParams, any]): string | null => {
    return params.get("promo")
}

export default SalesPortalRoutes