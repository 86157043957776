import React, {useEffect, useState} from "react";
import {fetchPriceForPlanWithPromo} from "../../../Api/Sales/PricesApi";
import {ApplicationContext} from "../../../ApplicationContext";
import {SimOnlyPlan} from "../../../Model/Sales/SimOnlyPlan";
import {PlanPrice} from "../../../Model/Sales/PlanPrice";
import {UserDetails} from "../../../Model/User/UserDetails";
import {isRequestError, RequestError} from "../../../Model/RequestError";
import {fetchSimOnlyPlanByCode} from "../../../Api/Sales/SimOnlyPlanApi";
import {SALES_PORTAL_ROUTES} from "../../../Routes/SalesPortalRoutes";
import {useNavigate} from "react-router-dom";

const PromoCodeComponent = (props: {componentData: {
        readonly selectedPlan: SimOnlyPlan;
        readonly selectedPlanPriceWithPromo: PlanPrice | null;
        readonly userDetails: UserDetails;
    },
    setSelectedPlanPriceWithPromo: (planPrice: PlanPrice | null) => void,
    setSelectedPlan: (simOnlyPlan: SimOnlyPlan) => void
}): JSX.Element | null => {
    const initialInputState = {
        value: '',
        className: 'form-control promoCode',
        disabled: false,
    }
    const initialButtonState = {
        text: "Apply",
        className: 'btn btn-secondary',
        disabled: false,
    }
    const [inputState, setInputState] = useState(initialInputState);
    const [buttonState, setButtonState] = useState(initialButtonState);

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputState(prevState => ({
            ...prevState, value: event.target.value
        }))
    };
    const staffPromoCode = applicationContext.appConfig.signupConfiguration.staffPromo
    const forcedToStaffPromo = !!props.componentData.userDetails.hybris?.cardNumber?.startsWith("2660")

    const queryParams = new URLSearchParams(window.location.search);

    let handleClick = async () => {
        sessionStorage.clear();

        if (buttonState.text === "Apply") {
            await handleApplyClick();
        } else {
            await handleRemoveClick();
        }
    };

    async function handleApplyClick() {
        const promoCode = inputState.value;
        try {
            // If promo is the same as for staff, but customer is not staff
            if (!forcedToStaffPromo && staffPromoCode === promoCode) {
                applyInvalidPromoStyles(promoCode)
                return;
            }

            const maybePlanPrice = await fetchPlanPriceWithPromo(promoCode)

            if (isRequestError(maybePlanPrice)) {
                applyInvalidPromoStyles(promoCode)
            } else {
                props.setSelectedPlanPriceWithPromo(maybePlanPrice)
                applyPromoStyles(promoCode)
                addPromoCodeToQueryParam(promoCode)
            }
        } catch (error) {
            applyInvalidPromoStyles(promoCode)
        }
    }

    async function handleRemoveClick() {
        try {
            const selectedPlan = await fetchSimOnlyPlanByCode(
                applicationContext.reseller,
                applicationContext.salesPortalApiDomain,
                props.componentData.selectedPlan.packageCode
            );

            if (isRequestError(selectedPlan)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            } else {
                clearPromoStyles()
                deletePromoCodeFromQueryParam()

                props.setSelectedPlan(selectedPlan)
                props.setSelectedPlanPriceWithPromo(null)
            }
        } catch (error) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
        }
    }

    async function handleQueryParamPromoCode(queryParamPromoCode: string | null) {
        if (queryParamPromoCode === null) return;

        if (forcedToStaffPromo) {
            if (staffPromoCode === null) return;
            const planPrice = await fetchPlanPriceWithPromo(staffPromoCode);
            await handlePlanPriceWithPromoResponse(planPrice, queryParamPromoCode);
            handleForcedToStaffPromo()
        } else {
            const planPrice = await fetchPlanPriceWithPromo(queryParamPromoCode);
            await handlePlanPriceWithPromoResponse(planPrice, queryParamPromoCode);
        }
    }
    async function fetchPlanPriceWithPromo(promoCode: string) {
        return await fetchPriceForPlanWithPromo(
            applicationContext.reseller,
            applicationContext.salesPortalApiDomain,
            props.componentData.selectedPlan.packageCode,
            promoCode
        );
    }
    async function handlePlanPriceWithPromoResponse(planPrice: PlanPrice | RequestError, promoCode: string) {
        try {
            if (isRequestError(planPrice)) {
                applyInvalidPromoStyles(promoCode)
                deletePromoCodeFromQueryParam()
            } else {
                applyPromoStyles(planPrice.promoCode ?? '' )

                props.setSelectedPlanPriceWithPromo(planPrice)
                addPromoCodeToQueryParam(promoCode);
            }
        } catch (e) {
            applyInvalidPromoStyles(promoCode)
            deletePromoCodeFromQueryParam()
        }
    }

    function addPromoCodeToQueryParam(promoCode: string) {
        queryParams.set(PROMO_CODE_QUERY_NAME, promoCode);
        const newUrl = `${window.location.origin}${window.location.pathname}?${queryParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
    }

    function deletePromoCodeFromQueryParam() {
        queryParams.delete(PROMO_CODE_QUERY_NAME);
        const newUrl = `${window.location.origin}${window.location.pathname}?${queryParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
    }

    function handleInitialRender() {
        let isPlanPriceWithPromo = props.componentData.selectedPlanPriceWithPromo !== null
        if (isPlanPriceWithPromo) {
            applyPromoStyles(props.componentData.selectedPlanPriceWithPromo?.promoCode ?? '')
        }
    }
    function applyPromoStyles(promoCode: string) {
        setInputState({ ...inputState, className: 'form-control is-valid', disabled: true, value: promoCode });
        setButtonState({ ...buttonState, className: 'btn btn-outline-danger', text: 'Remove' });
        if (forcedToStaffPromo) {
            // Disable remove button as if customer is staff, they can only have staff promo
            setButtonState(prevState => ({ ...prevState, disabled: true }));
        }
    }
    function applyInvalidPromoStyles(promoCode: string){
        setInputState({ ...inputState, className: 'form-control is-invalid', value: promoCode, disabled: false });
        setButtonState({ ...buttonState, className: 'btn btn-secondary', text: 'Apply', disabled: false});
    }
    function clearPromoStyles() {
        setInputState(initialInputState)
        setButtonState(initialButtonState)
    }

    const handlePromoCodeInLocalStorage = () => {
        const promoCode = getPromoCodeFromLocalStorage()
        if (promoCode !== null){
            addPromoCodeToQueryParam(promoCode)
            removePromoCodeFromLocalStorage()
        }
    };
    const handleForcedToStaffPromo = () => {
        // Remove promoCode from url param as forcing staff promo
        if (forcedToStaffPromo){
            deletePromoCodeFromQueryParam()
        }
    };
    useEffect(() => {
        sessionStorage.clear();
        handlePromoCodeInLocalStorage()
        let queryParamPromoCode = queryParams.get('promo')
        let renderFromQueryParamPromoCode = queryParamPromoCode !== null &&
            props.componentData.selectedPlanPriceWithPromo?.promoCode !== queryParamPromoCode

        if (renderFromQueryParamPromoCode){
            handleQueryParamPromoCode(queryParamPromoCode);
        } else {
            handleInitialRender();
        }
        return () => {
        };
    }, []);

    return forcedToStaffPromo ? null :
        <div className={"input-group mb-3"}>
            <label>Promo code:</label>
            <input disabled={inputState.disabled} type={"text"} className={inputState.className} placeholder={"Type promo code here..."}
                   aria-label={"Type promo code here..."} value={inputState.value} onChange={handleChange}/>
            <button className={buttonState.className} type={"button"} id={"promoCodeBtn"} onClick={handleClick}
                    disabled={buttonState.disabled}>{buttonState.text}</button>
            <div id="validationPromoCode" className="invalid-feedback">
                Promo code is invalid.
            </div>
            <div className="valid-feedback">
                Promo code applied!
            </div>
        </div>
}

const PROMO_CODE_QUERY_NAME: string = "promo"
export const setPromoCodeToLocalStorage = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const promoCode = queryParams.get(PROMO_CODE_QUERY_NAME)
    if (promoCode !== null){
        localStorage.setItem(PROMO_CODE_QUERY_NAME, promoCode)
    }
}
const getPromoCodeFromLocalStorage = () => {
    return localStorage.getItem(PROMO_CODE_QUERY_NAME)
}
const removePromoCodeFromLocalStorage = () => {
    localStorage.removeItem(PROMO_CODE_QUERY_NAME)
}
export default PromoCodeComponent; setPromoCodeToLocalStorage()