import * as React from "react"
import {SimOnlyPlan, simOnlyPlanDataLimit, simOnlyPlanMonthlyCost} from "../../Model/Sales/SimOnlyPlan";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {addPromoQueryParam, SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {ApplicationContext} from "../../ApplicationContext";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";

interface StandardSimOnlyPlansProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of all SIM-only plans provided by the reseller.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the standard SIM-only plans page.
 */
const StandardSimOnlyPlans = (props: StandardSimOnlyPlansProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    const optPageTitle = optHtmlContent(props.cmsContent, "sp-title-sim-only-deals-page")
    const optHowSimOnlyPlansWorkPane = optHtmlContent(props.cmsContent, "sp-pane-how-sim-only-plans-work")
    const optSwitchingToUsPane = optHtmlContent(props.cmsContent, "sp-pane-switching-to-us")
    const optFaqsPane = optHtmlContent(props.cmsContent, "sp-pane-faq")
    const optPaymentAndDelivery = optHtmlContent(props.cmsContent, "sp-pane-payment-and-delivery")

    /**
     * Component will dynamically render the SIM-only plans pane by first converting the given list of SimOnlyPlans
     * into HTML that follows the "sp-card-sim-only-plan-full" template. Once converted, this HTML will be injected
     * into the "sp-pane-sim-only-plans" dynamic component to create the pane.
     */
    const SimOnlyPlans = (): JSX.Element | null => {
        const optSimOnlyPlansPane = optHtmlContent(props.cmsContent, "sp-pane-sim-only-plans")
        const optSimOnlyPlanCard = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-full")

        if (optSimOnlyPlansPane && optSimOnlyPlanCard) {
            const simOnlyPlanCards = props.simOnlyPlans.map((simOnlyPlan) =>
                renderDynamicContent(optSimOnlyPlanCard, [
                    {key: "DATA_ALLOWANCE", value: simOnlyPlanDataLimit(simOnlyPlan)},
                    {key: "MONTHLY_PRICE", value: simOnlyPlanMonthlyCost(simOnlyPlan).toString()},
                    {
                        key: "VIEW_PLAN_LINK",
                        value: addPromoQueryParam(SALES_PORTAL_ROUTES.SimOnly.Details(applicationContext.urlContext, simOnlyPlan.packageCode), simOnlyPlan.promoCode)
                    }
                ])
            )

            return renderDynamicContent(optSimOnlyPlansPane, [
                {key: "SIM_ONLY_PLANS", value: simOnlyPlanCards}
            ])
        } else {
            return null
        }
    }

    return (
        <HeaderFooterWrapper cmsContent={props.cmsContent}>
            {optPageTitle && renderDynamicContent(optPageTitle)}

            <SimOnlyPlans/>

            {optHowSimOnlyPlansWorkPane && renderDynamicContent(optHowSimOnlyPlansWorkPane)}

            {optSwitchingToUsPane && renderDynamicContent(optSwitchingToUsPane)}

            {optFaqsPane && renderDynamicContent(optFaqsPane)}

            {optPaymentAndDelivery && renderDynamicContent(optPaymentAndDelivery)}
        </HeaderFooterWrapper>
    )

}

export default StandardSimOnlyPlans