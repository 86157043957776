import * as React from "react"
import {
    simOnlyOldDataAllowance, simOnlyOldMonthlyCost,
    SimOnlyPlan,
    simOnlyPlanDataLimit,
    simOnlyPlanMonthlyCost
} from "../../Model/Sales/SimOnlyPlan";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {addPromoQueryParam, SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {ApplicationContext} from "../../ApplicationContext";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {Navigate} from "react-router-dom";
import {CmsPlanCardMappingSchema} from "../../Model/CmsContent/CmsPlanCardMappingSchema";
import {ContentType, PromotionCardContent} from "../../Model/Sales/PromotionCardContent";
import {fetchAvailablePromotionCardContent} from "../../Api/Sales/PromotionsApi";

interface SuperdrugSimOnlyPlansProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of all SIM-only plans provided by the reseller.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the Superdrug-specific SIM-only plans page.
 */
const SuperdrugSimOnlyPlans = (props: SuperdrugSimOnlyPlansProps): JSX.Element => {

    const [promotionCardContentExpanded , setPromotionCardContentExpanded ] = React.useState<PromotionCardContent[]>([])
    const [promotionCardContentLoading, setPromotionCardContentLoading] = React.useState<boolean>(true)

    const applicationContext = React.useContext(ApplicationContext)

    const setupPromotionCardContentExpanded = async () => {
        const promotionCardContent = await fetchAvailablePromotionCardContent(
            applicationContext.salesPortalApiDomain,
            applicationContext.reseller,
            ContentType.PROMO_EXPANDED
        )
        setPromotionCardContentExpanded(promotionCardContent)
        setPromotionCardContentLoading(false)
    }

    React.useEffect(() => {
        setupPromotionCardContentExpanded()
    }, [])
    /**
     * Component will dynamically render the SIM-only plans pane by first converting the given list of SimOnlyPlans
     * into HTML that follows the "sp-card-sim-only-plan-full" template. Once converted, this HTML will be injected
     * into the "sp-pane-sim-only-plans" dynamic component to create the pane.
     */
    const SimOnlyPlans = (): JSX.Element | null => {
        const optPlanToCardMapping = props.cmsContent.find((content) => content.reference === "sales-portal-plan-card-mapping")

        if (optPlanToCardMapping && !promotionCardContentLoading) {
            const cardMappingOptions = optPlanToCardMapping.jsonContent as CmsPlanCardMappingSchema

            const standardSimOnlyPlans = props.simOnlyPlans
                .filter(plan => plan.promoCode === null)
                .map((simOnlyPlan, index) => {
                const optCardMapping = cardMappingOptions.ourPlansCardMapping
                    .find((planMapping) => planMapping.planCode === simOnlyPlan.packageCode || planMapping.default)
                const optCardContent = optCardMapping ? optHtmlContent(props.cmsContent, optCardMapping.cardReference) : undefined

                const optPromoCardContent = promotionCardContentExpanded[0]

                if (optCardMapping && optCardContent) {
                    return renderDynamicContent(optCardContent, [
                        {
                            key: "DATA_ALLOWANCE",
                            value: optCardMapping.overridePlanDataAllowance || simOnlyPlanDataLimit(simOnlyPlan)
                        },
                        {
                            key: "MONTHLY_PRICE",
                            value: optCardMapping.overridePlanCost || simOnlyPlanMonthlyCost(simOnlyPlan).toString()
                        },
                        {key: "PLAN_CODE", value: simOnlyPlan.packageCode},
                        {key: "PLAN_COLOUR", value: optCardMapping.planColour},
                        {key: "LINK_SUFFIX", value: optCardMapping.linkSuffix || ""},
                        {key: "CARD_CLASS", value: optCardMapping.cardClass || ""},
                        {key: "TITLE_SUFFIX", value: optCardMapping.cardSubtitle || "data"},
                        {key: "FOOTER", value: optCardMapping.cardFooter || ""}
                    ])

                } else {
                    return null
                }
            })
            const promoSimOnlyPlans = props.simOnlyPlans
                .filter(plan => plan.promoCode !== null)
                .map((simOnlyPlan, index) => {
                    const promoCardExpanded= promotionCardContentExpanded.find((card) =>
                        card.promoCode === simOnlyPlan.promoCode && card.planCode === simOnlyPlan.packageCode) ?? null

                    if (promoCardExpanded !== null){
                        return renderDynamicContent(promoCardExpanded.htmlContentString, [
                            {key: "OLD_MONTHLY_PRICE", value: simOnlyPlan.promoDiscount?.value ? simOnlyOldMonthlyCost(simOnlyPlan) : null},
                            {key: "OLD_DATA_ALLOWANCE", value: simOnlyPlan.promoAddonLimit ? simOnlyOldDataAllowance(simOnlyPlan) : null},
                            {key: "DATA_ALLOWANCE", value: simOnlyPlanDataLimit(simOnlyPlan)},
                            {key: "MONTHLY_PRICE", value: simOnlyPlanMonthlyCost(simOnlyPlan)},
                            {key: "BUY_PLAN_LINK", value: addPromoQueryParam(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext) + "?plan=" + simOnlyPlan.packageCode, simOnlyPlan.promoCode)}
                        ])
                    } else {
                        return null
                    }
                })


            const nonNullStandardSimOnlyPlans = standardSimOnlyPlans.flatMap((opt) => opt ? [opt] : [])
            const nonNullPromoSimOnlyPlans = promoSimOnlyPlans.flatMap((opt) => opt ? [opt] : [])
            const allSimOnlyPlans = nonNullStandardSimOnlyPlans.concat(nonNullPromoSimOnlyPlans)

            return <div>
                {/*<div id="standard-sim-deals" style={{*/}
                {/*    display: "flex",*/}
                {/*    flexWrap: "wrap",*/}
                {/*    justifyContent: "center",*/}
                {/*    gap: "2%",*/}
                {/*    rowGap: "40px",*/}
                {/*    flexFlow: "wrap",*/}
                {/*    marginBottom: "40px"*/}
                {/*}}>*/}
                {/*    <React.Fragment>{nonNullStandardSimOnlyPlans}</React.Fragment>*/}
                {/*</div>*/}
                <div id="promo-sim-deals" style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: "2%",
                    rowGap: "40px",
                    flexFlow: "wrap",
                }}>
                    <React.Fragment>{allSimOnlyPlans}</React.Fragment>
                </div>
            </div>

        } else {
            return null
        }
    }

    const optOurPlansPage = optHtmlContent(props.cmsContent, "sp-page-our-plans")

    if (!optOurPlansPage) {
        return <Navigate to={SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext)}/>
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent}>
                {renderDynamicContent(optOurPlansPage, [
                    {key: "SIM_ONLY_PLANS", value: <SimOnlyPlans/>}
                ])}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugSimOnlyPlans