import axios from "axios"
import {isSimOnlyPlan, SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import {isRequestError, RequestError} from "../../Model/RequestError";
import {acceptHeader, executeWithErrorResponseHandling} from "../ApiUtilities";
import {Reseller} from "../../Types/Reseller";
import {joinUrl} from "../../Utilities/UriUtilities";
import {readSessionItem} from "../../Utilities/SessionUtilities";
import {isArrayOf} from "../../Utilities/TypeGuardUtilities";

/**
 * Function is responsible for retrieving all known SIM-only plans.
 */
export const fetchSimOnlyPlans = async (
    reseller: Reseller,
    domain: string
): Promise<SimOnlyPlan[] | RequestError> => {
    const maybeSessionData = readSessionItem<SimOnlyPlan[] | null>(
        "sim-only-plans", (a: any): a is SimOnlyPlan[] => isArrayOf(a, isSimOnlyPlan))
    if (maybeSessionData !== null && maybeSessionData.length === 0) {
        return Promise.resolve(maybeSessionData)
    }

    const endpoint = joinUrl(domain, `/${reseller}/plan/sim-only/listAll`)
    const headers = {...acceptHeader()}

    const response = await executeWithErrorResponseHandling<SimOnlyPlan[]>(() =>
        axios.get(endpoint, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return response.data
    }
}

/**
 * Function is responsible for retrieving SIM-only plans with the largest
 * data allowance.
 */
export const fetchBestSimOnlyPlans = async (
    reseller: Reseller,
    domain: string
): Promise<SimOnlyPlan[] | RequestError> => {
    const endpoint = joinUrl(domain, `/${reseller}/plan/sim-only/best-deals`)
    const headers = {...acceptHeader()}

    const response = await executeWithErrorResponseHandling<SimOnlyPlan[]>(() =>
        axios.get(endpoint, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return response.data
    }
}

/**
 * Function is responsible for retrieving a single SIM-only plan with the
 * given plan code.
 */
export const fetchSimOnlyPlanByCode = async (
    reseller: Reseller,
    domain: string,
    planCode: string
): Promise<SimOnlyPlan | RequestError> => {
    const maybeSessionData = readSessionItem<SimOnlyPlan[] | null>(
        "sim-only-plans", (a: any): a is SimOnlyPlan[] => isArrayOf(a, isSimOnlyPlan))
    const maybePlan = maybeSessionData?.find((simOnlyPlan) => simOnlyPlan.packageCode === planCode)

    if (maybePlan) {
        return maybePlan
    }

    const endpoint = joinUrl(domain, `/${reseller}/plan/sim-only/view/${planCode}`)
    const headers = {...acceptHeader()}

    const response = await executeWithErrorResponseHandling<SimOnlyPlan>(() =>
        axios.get(endpoint, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return response.data
    }
}

export const fetchSimOnlyPlanByPlanCodeAndPromoCode = async (
    reseller: Reseller,
    domain: string,
    planCode: string,
    promoCode: string | null
): Promise<SimOnlyPlan | RequestError> => {
    const maybeSessionData = readSessionItem<SimOnlyPlan[] | null>(
        "sim-only-plans", (a: any): a is SimOnlyPlan[] => isArrayOf(a, isSimOnlyPlan))
    const maybePlan = maybeSessionData?.find((simOnlyPlan) =>
        simOnlyPlan.packageCode === planCode && simOnlyPlan.promoCode === promoCode
    )

    if (maybePlan) {
        return maybePlan
    }

    const endpoint = joinUrl(domain, `/${reseller}/plan/sim-only/view/${planCode}${promoCode ? `/${promoCode}` : ""}`)
    const headers = {...acceptHeader()}

    const response = await executeWithErrorResponseHandling<SimOnlyPlan>(() =>
        axios.get(endpoint, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return response.data
    }
}
