import * as React from "react"
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import {ApplicationContext} from "../../ApplicationContext";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {addPromoQueryParam, getPromoQueryParam, SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {fetchSimOnlyPlanByCode, fetchSimOnlyPlanByPlanCodeAndPromoCode} from "../../Api/Sales/SimOnlyPlanApi";
import {isRequestError} from "../../Model/RequestError";
import SuperdrugSelectedPlanDetailsCardSimInHand
    from "../../Components/Superdrug/Card/SuperdrugSelectedPlanDetailsCardSimInHand";
import {PlanPrice} from "../../Model/Sales/PlanPrice";
import {fetchHybrisUser} from "../../Api/Hybris/UserManagementApi";
import {fetchPriceForPlanWithPromo} from "../../Api/Sales/PricesApi";

interface WantToKeepYourNumberCardProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Details on the plan that has been selected by the customer.
     */
    readonly selectedPlan: SimOnlyPlan

}

/**
 * Function will render the "want to keep your number" card.
 */
const WantToKeepYourNumberCard = (props: WantToKeepYourNumberCardProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)

    const optKeepingYourNumberCard = optHtmlContent(props.cmsContent, "sp-card-keeping-your-number")

    if (optKeepingYourNumberCard) {
        return renderDynamicContent(optKeepingYourNumberCard, [
            {
                key: "SETUP_NUMBER_LINK",
                value: addPromoQueryParam(SALES_PORTAL_ROUTES.Port.PortCapture(
                    applicationContext.urlContext, props.selectedPlan.packageCode), props.selectedPlan.promoCode)
            },
            {
                key: "SIGNUP_LINK",
                value: addPromoQueryParam(SALES_PORTAL_ROUTES.SimInHand.Purchase(
                    applicationContext.urlContext, props.selectedPlan.packageCode), props.selectedPlan.promoCode)
            }
        ])
    } else {
        return null
    }
}

interface SuperdrugPortSelectionPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the Superdrug-specific port capture page which will ask the user if they want to
 * port onto the network as part of the signup journey.
 */
const SuperdrugPortSelectionPage = (props: SuperdrugPortSelectionPageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()
    const {planCode} = useParams<keyof { readonly planCode: string }>()
    const promoCode = getPromoQueryParam(useSearchParams())

    const [componentData, setComponentData] = React.useState<{
        readonly selectedPlan: SimOnlyPlan
        readonly inStorePlan: SimOnlyPlan
        readonly planPrice: PlanPrice | null
        readonly inStorePlanPrice: PlanPrice | null
    }>()

    /**
     * Function will fetch all the information needed for this component before rendering it.
     */
    const setupComponent = async () => {
        const selectedPlanCode = planCode ?? applicationContext.appConfig.signupConfiguration.defaultPlan!!

        const maybeToken = await applicationContext.accessToken()
        if (!maybeToken) {
            navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
            return
        }

        const maybeUser = await fetchHybrisUser(applicationContext.salesPortalApiDomain, maybeToken)
        if (isRequestError(maybeUser)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        const maybePlan = await fetchSimOnlyPlanByPlanCodeAndPromoCode(
            applicationContext.reseller, applicationContext.salesPortalApiDomain, selectedPlanCode, promoCode)
        if (isRequestError(maybePlan)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        let inStorePlan: SimOnlyPlan
        if (applicationContext.appConfig.signupConfiguration.defaultPlan === selectedPlanCode) {
            inStorePlan = maybePlan
        } else {
            const maybeInStorePlan = await fetchSimOnlyPlanByCode(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, applicationContext.appConfig.signupConfiguration.defaultPlan!!)
            if (isRequestError(maybeInStorePlan)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            inStorePlan = maybeInStorePlan
        }

        const optPromoCode = maybeUser.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null
        let planPriceWithPromo: PlanPrice | null
        if (optPromoCode) { // Customer hasn't selected a plan yet for SIH journey.
            const maybePlanPrice = await fetchPriceForPlanWithPromo(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode!!, optPromoCode)
            if (isRequestError(maybePlanPrice)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            planPriceWithPromo = maybePlanPrice
        } else {
            planPriceWithPromo = null
        }

        let inStorePriceWithPromo: PlanPrice | null
        if (optPromoCode) {
            if (planPriceWithPromo && applicationContext.appConfig.signupConfiguration.defaultPlan === selectedPlanCode) {
                inStorePriceWithPromo = planPriceWithPromo
            } else {
                const maybeInStorePlanPrice = await fetchPriceForPlanWithPromo(
                    applicationContext.reseller, applicationContext.salesPortalApiDomain, applicationContext.appConfig.signupConfiguration.defaultPlan!!, optPromoCode)
                if (isRequestError(maybeInStorePlanPrice)) {
                    navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                    return
                }
                inStorePriceWithPromo = maybeInStorePlanPrice
            }
        } else {
            inStorePriceWithPromo = null
        }

        setComponentData({
            selectedPlan: maybePlan,
            inStorePlan: inStorePlan,
            planPrice: planPriceWithPromo,
            inStorePlanPrice: inStorePriceWithPromo
        })
    }

    React.useEffect(() => {
        setupComponent()
    }, [planCode])

    const optGenericCardWithSelectedPlanPage = optHtmlContent(props.cmsContent, "sp-page-generic-card-with-selected-plan")

    if (!optGenericCardWithSelectedPlanPage || !componentData) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="tertiary">
                {renderDynamicContent(optGenericCardWithSelectedPlanPage, [
                    {
                        key: "SELECTED_PLAN_INFO_CARD",
                        value: <SuperdrugSelectedPlanDetailsCardSimInHand
                            cmsContent={props.cmsContent}
                            selectedPlan={componentData.selectedPlan}
                            paidInStorePlan={componentData.inStorePlan}
                            priceWithPromo={componentData.planPrice}
                            paidInStorePriceWithPromo={componentData.inStorePlanPrice}
                        />
                    },
                    {
                        key: "CARD_CONTENT",
                        value: <WantToKeepYourNumberCard
                            cmsContent={props.cmsContent}
                            selectedPlan={componentData.selectedPlan}
                        />
                    }
                ])}
            </HeaderFooterWrapper>
        )
    }
}

export default SuperdrugPortSelectionPage