import * as React from "react"
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {
    simOnlyOldDataAllowance,
    simOnlyOldMonthlyCost,
    SimOnlyPlan,
    simOnlyPlanDataLimit,
    simOnlyPlanMonthlyCost
} from "../../Model/Sales/SimOnlyPlan";
import {addPromoQueryParam, SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {ApplicationContext} from "../../ApplicationContext";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {CmsCDN} from "../../Model/CmsContent/CmsCDN";
import {Navigate} from "react-router-dom";
import {CmsPlanCardMappingSchema} from "../../Model/CmsContent/CmsPlanCardMappingSchema";
import {LatestBlogPostPane} from "../BlogListPage/BlogListPage";
import {ContentType, PromotionCardContent} from "../../Model/Sales/PromotionCardContent";
import {fetchAvailablePromotionCardContent} from "../../Api/Sales/PromotionsApi";

interface SuperdrugSplashPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of the best SIM-only plans provided by the reseller to be
     * displayed on the splash screen.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the splash page for Superdrug.
 */
const SuperdrugSplashPage = (props: SuperdrugSplashPageProps): JSX.Element => {

    const [promotionCardContentStandard , setPromotionCardContentStandard ] = React.useState<PromotionCardContent[]>([])
    const [promotionCardContentLoading, setPromotionCardContentLoading] = React.useState<boolean>(true)

    const applicationContext = React.useContext(ApplicationContext)
    /**
     * Component will dynamically render the best SIM-only plans pane by first converting the given list of SimOnlyPlans
     * into HTML that follows the "sp-card-sim-only-plan-limited" template. Once converted, this HTML will be
     * injected into the "sp-pane-best-sim-only-plans" dynamic component to create the pane.
     */


    const setupPromotionCardContentExpanded = async () => {
        const promotionCardContent = await fetchAvailablePromotionCardContent(
            applicationContext.salesPortalApiDomain,
            applicationContext.reseller,
            ContentType.PROMO_STANDARD
        )
        setPromotionCardContentStandard(promotionCardContent)
        setPromotionCardContentLoading(false)
    }

    React.useEffect(() => {
        setupPromotionCardContentExpanded()
    }, [])

    const BestSimOnlyPlans = (): JSX.Element | null => {
        if (!promotionCardContentLoading) {
            const limitedSimOnlyPlanCards = props.simOnlyPlans
                .filter(plan => plan.promoCode !== null)
                .map((simOnlyPlan, index) => {
                    const optPromoCardContent = promotionCardContentStandard
                        .find( card => card.promoCode === simOnlyPlan.promoCode && card.planCode === simOnlyPlan.packageCode)

                    if (optPromoCardContent) {
                        return renderDynamicContent(optPromoCardContent.htmlContentString, [
                            {key: "OLD_DATA_ALLOWANCE", value: simOnlyPlan.promoAddonLimit ? simOnlyOldDataAllowance(simOnlyPlan) : null},
                            {key: "OLD_MONTHLY_PRICE", value: simOnlyPlan.promoDiscount?.value ? simOnlyOldMonthlyCost(simOnlyPlan) : null},
                            {key: "DATA_ALLOWANCE", value: simOnlyPlanDataLimit(simOnlyPlan)},
                            {key: "MONTHLY_PRICE", value: simOnlyPlanMonthlyCost(simOnlyPlan).toString()},
                            {
                                key: "VIEW_PLAN_LINK",
                                value: addPromoQueryParam(SALES_PORTAL_ROUTES.SimOnly.Details(applicationContext.urlContext, simOnlyPlan.packageCode), simOnlyPlan.promoCode)
                            }
                        ])
                    } else {
                        return null
                    }
            })

            const filteredSimOnlyPlanCards = limitedSimOnlyPlanCards.flatMap((opt) => opt ? [opt] : [])

            return <div id="promoPlans" style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "2%",
                rowGap: "40px",
                fontFamily: "'Gotham Rounded SSm A', 'Gotham Rounded SSm B', sans-serif"
            }}>
                <React.Fragment>{filteredSimOnlyPlanCards}</React.Fragment>
            </div>
        } else {
            return null
        }
    }

    React.useEffect(() => {
        // Trustpilot script is maintained by morons so we need to re-apply it here.
        const optTrustpilotCdn = props.cmsContent.find((content) =>
            content.reference === "trustpilot-cdn")
        if (optTrustpilotCdn && optTrustpilotCdn.jsonContent) {
            const cdnSource = optTrustpilotCdn.jsonContent as CmsCDN
            const script = document.createElement("script")
            script.src = cdnSource.href
            document.head.appendChild(script)
        }
    }, [])

    const optHomePage = optHtmlContent(props.cmsContent, "sp-page-home")

    if (!optHomePage) {
        return <Navigate to={SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext)}/>
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent}>
                {renderDynamicContent(optHomePage, [
                    {key: "DYNAMIC_SIM_ONLY_PROMO_PLANS", value: <BestSimOnlyPlans/>},
                    {key: "BLOG_LATEST", value: <LatestBlogPostPane cmsContent={props.cmsContent} />}
                ])}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugSplashPage