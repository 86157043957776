import * as React from "react"
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {SimOnlyPlan, simOnlyPlanDataLimit, simOnlyPlanMonthlyCost} from "../../Model/Sales/SimOnlyPlan";
import {addPromoQueryParam, SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {ApplicationContext} from "../../ApplicationContext";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";

interface StandardSplashPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of the best SIM-only plans provided by the reseller to be
     * displayed on the splash screen.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the splash page for standard installs.
 */
const StandardSplashPage = (props: StandardSplashPageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    const optPageTitle = optHtmlContent(props.cmsContent, "sp-title-splash-page")
    const optChoosingUsPane = optHtmlContent(props.cmsContent, "sp-pane-choosing-us")
    const optSwitchingToUsPane = optHtmlContent(props.cmsContent, "sp-pane-switching-to-us")
    const optFaqsPane = optHtmlContent(props.cmsContent, "sp-pane-faq")

    /**
     * Component will dynamically render the best SIM-only plans pane by first converting the given list of SimOnlyPlans
     * into HTML that follows the "sp-card-sim-only-plan-limited" template. Once converted, this HTML will be
     * injected into the "sp-pane-best-sim-only-plans" dynamic component to create the pane.
     */
    const BestSimOnlyPlans = (): JSX.Element | null => {
        const optBestSimOnlyPlansPane = optHtmlContent(props.cmsContent, "sp-pane-best-sim-only-plans")
        const optLimitedSimOnlyPlanCard = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-limited")

        if (optBestSimOnlyPlansPane && optLimitedSimOnlyPlanCard) {
            const limitedSimOnlyPlanCards = props.simOnlyPlans.map((simOnlyPlan) =>
                renderDynamicContent(optLimitedSimOnlyPlanCard, [
                    {key: "DATA_ALLOWANCE", value: simOnlyPlanDataLimit(simOnlyPlan)},
                    {key: "MONTHLY_PRICE", value: simOnlyPlanMonthlyCost(simOnlyPlan).toString()},
                    {
                        key: "VIEW_PLAN_LINK",
                        value: addPromoQueryParam(SALES_PORTAL_ROUTES.SimOnly.Details(applicationContext.urlContext, simOnlyPlan.packageCode), simOnlyPlan.promoCode)
                    }
                ])
            )

            return renderDynamicContent(optBestSimOnlyPlansPane, [
                {key: "SIM_ONLY_PLANS", value: limitedSimOnlyPlanCards}
            ])
        } else {
            return null
        }
    }

    return (
        <HeaderFooterWrapper cmsContent={props.cmsContent}>
            {optPageTitle && renderDynamicContent(optPageTitle)}

            <BestSimOnlyPlans/>

            {optChoosingUsPane && renderDynamicContent(optChoosingUsPane)}

            {optSwitchingToUsPane && renderDynamicContent(optSwitchingToUsPane)}

            {optFaqsPane && renderDynamicContent(optFaqsPane)}
        </HeaderFooterWrapper>
    )

}

export default StandardSplashPage