import {isPlanLimit, PlanLimit} from "./PlanLimit";
import {isPlanBolton, PlanBolton} from "./PlanBolton";
import {isNumber, isOptType, isString, isType} from "../../Utilities/TypeGuardUtilities";

/**
 * Interface defines the expected JSON structure of a SIM-only plan.
 */
export interface SimOnlyPlan {
    readonly packageCode: string
    readonly description: string
    readonly monthlyPrice: number
    readonly contractLength: number
    readonly dataPlanLimit: PlanLimit
    readonly voicePlanLimit: PlanLimit
    readonly smsPlanLimit: PlanLimit
    readonly planBolton: PlanBolton | null
    readonly promoCode: string | null
    readonly promoDiscount: {value: number, currency: string} | null
    readonly promoPercentageDiscount: number | null
    readonly promoAddonLimit: number | null
}

/**
 * Type-guard function will check if the given instance of any is of type SimOnlyPlan.
 */
export const isSimOnlyPlan = (a: any): a is SimOnlyPlan =>
    isString(a, "packageCode") &&
    isString(a, "description") &&
    isNumber(a, "monthlyPrice") &&
    isNumber(a, "contractLength") &&
    isType(a, "dataPlanLimit", isPlanLimit) &&
    isType(a, "voicePlanLimit", isPlanLimit) &&
    isType(a, "smsPlanLimit", isPlanLimit) &&
    isOptType(a, "planBolton", isPlanBolton)

/**
 * Utility function is responsible for converting the given SimOnlyPlans
 * contract length into a displayable string.
 */
export const simOnlyPlanContractLength = (simOnlyPlan: SimOnlyPlan): string => {
    if (simOnlyPlan.contractLength === 1) {
        return "1 Month"
    } else {
        return simOnlyPlan.contractLength + " Months"
    }
}

/**
 * Utility function returns the total cost of a given SIM-only plan.
 */
export const simOnlyPlanMonthlyCost = (simOnlyPlan: SimOnlyPlan): number =>
    simOnlyPlan.monthlyPrice - (simOnlyPlan.promoDiscount?.value ?? 0)

/**
 * Utility function returns the total data amount of this plan.
 */
export const simOnlyPlanDataLimit = (simOnlyPlan: SimOnlyPlan): string => {
    const isUnlimitedPlan = simOnlyPlan.dataPlanLimit.planLimitValue === null

    if (isUnlimitedPlan) {
        return "Unlimited"
    } else {
        const baseDataValue = simOnlyPlan.dataPlanLimit.planLimitValue!!
        const promoAddonData = simOnlyPlan.promoAddonLimit ?? 0
        const promoAddonDataInGB = promoAddonData / 1048576

        return baseDataValue +  promoAddonDataInGB + "GB"
    }
}

export const simOnlyOldDataAllowance = (simOnlyPlan: SimOnlyPlan): string => {
    return (simOnlyPlan.dataPlanLimit.planLimitValue?.toString() ?? "0") + "GB"
}

export const simOnlyOldMonthlyCost = (simOnlyPlan: SimOnlyPlan): string => {
    return simOnlyPlan.monthlyPrice.toString()
}

/**
 * Utility function returns the total voice amount for this plan.
 */
export const simOnlyPlanVoiceLimit = (simOnlyPlan: SimOnlyPlan): string => {
    const isUnlimitedPlan = simOnlyPlan.voicePlanLimit.planLimitValue === null
    return isUnlimitedPlan ? "Unlimited" : simOnlyPlan.voicePlanLimit.planLimitValue.toString()
}

/**
 * Utility function returns the total SMS amount for this plan.
 */
export const simOnlySmsLimit = (simOnlyPlan: SimOnlyPlan): string => {
    const isUnlimitedPlan = simOnlyPlan.smsPlanLimit.planLimitValue === null
    return isUnlimitedPlan ? "Unlimited" : simOnlyPlan.smsPlanLimit.planLimitValue.toString()
}

/**
 * Utility function will return true when the given SIM-only plan offers unlimited data either through the plan or an
 * unlimited data bolton.
 */
export const isUnlimitedDataPlan = (simOnlyPlan: SimOnlyPlan): boolean => {
    const hasUnlimitedDataBolton = simOnlyPlan.planBolton !== null &&
        simOnlyPlan.planBolton.unit === "GB" &&
        simOnlyPlan.planBolton.value === null


    return simOnlyPlan.dataPlanLimit.planLimitValue === null || hasUnlimitedDataBolton
}

